import Sender from './helpers/Sender';
import Modal from './helpers/Modal';

const reviewsPage = document.querySelector('.reviews');

if (reviewsPage) {
  const thankModal = new Modal('.modal-review-thank', null);
  thankModal.addCloseButton('.modal-thank__ok');
  thankModal.init();

  const orderSender = new Sender({
    url: '/ajax/review.php',
    labelClasses: ['.reviews__name', '.reviews__email', '.reviews__text'],
    buttonClass: '.reviews__button',
    onComplete: () => {
      thankModal.show();
    },
  });
  orderSender.init();
}
