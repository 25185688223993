import Sender from './helpers/Sender';
import Modal from './helpers/Modal';

const orderPage = document.querySelector('.order');

if (orderPage) {
  const thankModal = new Modal('.modal-thank', null);
  thankModal.addCloseButton('.modal-thank__ok');
  thankModal.init();

  const file = document.querySelector('input[type="file"]');
  file.addEventListener('change', () => {
    const span = document.querySelector('.order__file-visual span');
    if (file.files.length) {
      span.textContent = file.files[0].name;
      span.classList.add('loaded');
    } else {
      span.textContent = 'Прикрепить файл';
      span.classList.remove('loaded');
    }
  });

  const orderSender = new Sender({
    url: '/ajax/order.php',
    labelClasses: [
      '.order__name',
      '.order__phone',
      '.order__email',
      '.order__text',
      '.order__file-container',
    ],
    buttonClass: '.order__button',
    onComplete: () => {
      thankModal.show();

      const span = document.querySelector('.order__file-visual span');
      span.textContent = 'Прикрепить файл';
      span.classList.remove('loaded');

      ym(64420804, 'reachGoal', 'kick');
      ga('send', 'event', 'form', 'submit');
    },
  });
  orderSender.init();
}
