import Modal from './helpers/Modal';
import Sender from './helpers/Sender';

const thankModal = new Modal('.modal-thank', null);
thankModal.addCloseButton('.modal-thank__ok');
thankModal.init();

const callbackModal = new Modal('.modal-callback', '.header__button');
callbackModal.init();

const callbackSender = new Sender({
  url: '/ajax/callback.php',
  labelClasses: ['.modal-callback__name', '.modal-callback__phone'],
  buttonClass: '.modal-callback .form__confirm',
  onComplete: () => {
    callbackModal.close();
    thankModal.show();
    ym(64420804, 'reachGoal', 'kick');
    ga('send', 'event', 'form', 'submit');
  },
});
callbackSender.init();

const headerPriceButton = document.querySelector('.header__price');
const footerPriceButton = document.querySelector('.footer__price');
headerPriceButton.addEventListener('click', () => {
  footerPriceButton.click();
});
const priceModal = new Modal('.modal-price', '.footer__price');
priceModal.init();

const priceSender = new Sender({
  url: '/ajax/price.php',
  labelClasses: [
    '.modal-price__name',
    '.modal-price__phone',
    '.modal-price__email',
  ],
  buttonClass: '.modal-price .form__confirm',
  onComplete: () => {
    priceModal.close();
    thankModal.show();
    ym(64420804, 'reachGoal', 'kick');
    ga('send', 'event', 'form', 'submit');
  },
});
priceSender.init();

const costButton = document.querySelector('.product__cost');

const costModal = new Modal('.modal-cost', '.product__cost');
costModal.init();

const costSender = new Sender({
  url: '/ajax/cost.php',
  labelClasses: ['.modal-cost__name', '.modal-cost__phone'],
  buttonClass: '.modal-cost .form__confirm',
  onComplete: () => {
    costModal.close();
    thankModal.show();
    ym(64420804, 'reachGoal', 'kick');
    ga('send', 'event', 'form', 'submit');
  },
  additionalFields: {
    product: costButton && costButton.dataset.name,
  },
});
costSender.init();

const serviceButton = document.querySelector('.service__button');

const adviceModal = new Modal('.modal-advice', '.service__button');
adviceModal.init();

const adviceSender = new Sender({
  url: '/ajax/advice.php',
  labelClasses: ['.modal-advice__name', '.modal-advice__phone'],
  buttonClass: '.modal-advice .form__confirm',
  onComplete: () => {
    adviceModal.close();
    thankModal.show();
    ym(64420804, 'reachGoal', 'kick');
    ga('send', 'event', 'form', 'submit');
  },
  additionalFields: {
    service: serviceButton && serviceButton.dataset.service,
  },
});
adviceSender.init();

const policyModal = new Modal('.modal-policy', null);
policyModal.init();
const policyButtons = document.querySelectorAll('.policy-button');
policyButtons.forEach((button) => {
  button.addEventListener('click', (event) => {
    event.preventDefault();
    policyModal.show();
  });
});
