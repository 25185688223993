// SCSS
import './styles/main.scss';

// CODE
import './scripts/modals';
import './scripts/home';
import './scripts/catalog';
import './scripts/order';
import './scripts/reviews';
import './scripts/contacts';
import { initCartForm } from './scripts/cart';
import Cart from './scripts/helpers/Cart';

if (window.innerWidth <= 768) {
  const mobileMenuButton = document.querySelector('.mobile-menu-button');
  const nav = document.querySelector('.nav');
  mobileMenuButton.addEventListener('click', () => {
    nav.classList.add('active');
  });
  nav.addEventListener('click', (event) => {
    if (event.target === nav) {
      nav.classList.remove('active');
    }
  });
  const navItems = document.querySelectorAll('.nav__item--parent > a');
  navItems.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      if (event.target === item) {
        navItems.forEach((_item) => {
          if (_item !== item) {
            _item.parentElement.classList.remove('open');
          }
        });
        item.parentElement.classList.toggle('open');
      }
    });
  });

  const navSubItems = document.querySelectorAll(
    '.second-level.nav__subitem--parent > a'
  );
  navSubItems.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      console.log(event.target);

      navSubItems.forEach((_item) => {
        if (_item !== event.target) {
          _item.parentElement.classList.remove('open');
        }
      });
      event.target.parentElement.classList.toggle('open');
    });
  });

  const navSubSubItems = document.querySelectorAll(
    '.third-level.nav__subitem--parent > a'
  );
  navSubSubItems.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      console.log(event.target);

      navSubSubItems.forEach((_item) => {
        if (_item !== event.target) {
          _item.parentElement.classList.remove('open');
        }
      });
      event.target.parentElement.classList.toggle('open');
    });
  });
}

const cart = new Cart();

const cartButtons = document.querySelectorAll('.cart-button');
if (cartButtons.length > 0) {
  cartButtons.forEach((button) => {
    button.addEventListener('click', () => {
      cart.add({
        id: button.dataset.id,
        name: button.dataset.name,
        price: button.dataset.price,
      });
    });
  });
}

const cartList = document.querySelector('.cart__list');
if (cartList) {
  cart.fillCart(cartList);
}

const footer = document.querySelector('.footer');
const topFooter = document.querySelector('.footer__top');

const top = footer.getBoundingClientRect().top;
const footerPos = top - innerHeight + 56;

if (footerPos >= 0) {
  topFooter.classList.remove('free');
} else {
  topFooter.classList.add('free');
}

window.addEventListener('scroll', () => {
  const top = footer.getBoundingClientRect().top;
  const footerPos = top - innerHeight + 56;

  if (footerPos >= 0) {
    topFooter.classList.remove('free');
  } else {
    topFooter.classList.add('free');
  }
});

initCartForm(cart);
