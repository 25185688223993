import Swiper from 'swiper';

const initScreenSlider = () => {
  const slider = document.querySelector('.screen-slider .swiper-container');
  const prev = document.querySelector('.screen-slider__prev');
  const next = document.querySelector('.screen-slider__next');
  const currentSpan = document.querySelector('.screen-slider__current');

  new Swiper(slider, {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      prevEl: prev,
      nextEl: next,
    },
    on: {
      transitionEnd: function () {
        currentSpan.textContent = this.realIndex + 1;
      },
    },
  });
};

const initProductsButton = () => {
  const button = document.querySelector('.home-products__button');
  button.addEventListener('click', () => {
    const container = button.previousElementSibling;
    container.style.maxHeight = container.scrollHeight + 'px';
    if (container.classList.contains('show')) {
      container.style.removeProperty('max-height');
    }
    container.classList.toggle('show');
  });
};

const initMailSlider = () => {
  const slider = document.querySelector('.home-mails .swiper-container');
  const prev = document.querySelector('.home-mails__prev');
  const next = document.querySelector('.home-mails__next');

  new Swiper(slider, {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
    navigation: {
      prevEl: prev,
      nextEl: next,
    },
  });
};

const initPartnersSlider = () => {
  const slider = document.querySelector('.home-partners .swiper-container');
  const prev = document.querySelector('.home-partners__prev');
  const next = document.querySelector('.home-partners__next');

  new Swiper(slider, {
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
    navigation: {
      prevEl: prev,
      nextEl: next,
    },
  });
};

const mainPage = document.querySelector('.screen-slider');
if (mainPage) {
  initScreenSlider();
  initProductsButton();
  initMailSlider();
  initPartnersSlider();
}
