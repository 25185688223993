const initMap = () => {
  const contactsPage = document.querySelector('.contacts');
  if (!contactsPage) {
    return;
  }

  ymaps.ready(() => {
    const contactsMap = new ymaps.Map('map', {
      center: [45.040515, 39.087045],
      zoom: 12,
      controls: ['zoomControl'],
    });

    if (window.innerWidth < 992) {
      contactsMap.behaviors.disable('drag');
    }

    // Создаём макет иконки.
    const MyIconLayout = ymaps.templateLayoutFactory.createClass(`
      <div class="contacts__map-placemark">
        <span>Данфер</span>
      </div>
    `);

    const metallYugPlacemark = new ymaps.Placemark(
      [45.040515, 39.087045],
      {
        balloonContentHeader: 'ООО "Данфер"',
        balloonContentBody: 'г. Краснодар, ул. Симферопольская, 62',
        // balloonContentFooter: 'Мы пошли в гору',
        // hintContent: 'Зимние происшествия',
      },
      {
        iconLayout: MyIconLayout,
        iconShape: {
          type: 'Rectangle',
          // Прямоугольник описывается в виде двух точек - верхней левой и нижней правой.
          coordinates: [
            // [-38, -48],
            // [38, 48],
            [-32, -56],
            [32, 56],
          ],
        },
      }
    );

    contactsMap.geoObjects.add(metallYugPlacemark);
  });
};

initMap();
