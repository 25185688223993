import Modal from './Modal';

class Cart {
  constructor() {
    this.items = this._getFromStorage();
    this.cartQuantity = document.querySelector('.footer__cart-quantity');
    this._setQuantity();
    this.doneModal = new Modal('.modal-cart', null);
    this.doneModal.addCloseButton('.modal-cart__continue');
    this.doneModal.init();

    this.alreadyModal = new Modal('.modal-already', null);
    this.alreadyModal.addCloseButton('.modal-already__continue');
    this.alreadyModal.init();
  }

  _getFromStorage() {
    const storageStr = localStorage.getItem('cart');
    return storageStr ? JSON.parse(storageStr) : [];
  }

  _setQuantity() {
    this.cartQuantity.textContent = this.items.length;
    this.cartQuantity.style.display = this.items.length
      ? 'inline-flex'
      : 'none';
  }

  add(item) {
    if (this.items.some((_item) => _item.id === item.id)) {
      this.alreadyModal.show();
      return;
    }

    this.items.push(item);
    localStorage.setItem('cart', JSON.stringify(this.items));
    this._setQuantity();
    this.doneModal.show();
  }

  remove(item) {
    this.items = this.items.filter((_item) => _item.id !== item.id);
    localStorage.setItem('cart', JSON.stringify(this.items));
    this._setQuantity();
    // return this.items;
  }

  _createCartElement(item, cartList) {
    const div = document.createElement('div');
    div.classList.add('cart__item');
    const name = document.createElement('span');
    name.classList.add('cart__name');
    name.textContent = item.name;
    const price = document.createElement('span');
    price.classList.add('cart__price');
    price.textContent = item.price ? item.price : 'Цена по запросу';
    const remove = document.createElement('button');
    remove.classList.add('cart__remove');
    remove.addEventListener('click', () => {
      this.remove(item);
      this.fillCart(cartList);
    });
    div.appendChild(name);
    div.appendChild(price);
    div.appendChild(remove);
    return div;
  }

  fillCart(cartList) {
    if (this.items.length > 0) {
      const titleHTML = `
        <div class="cart__head">
          <span>Наименование</span>
          <span>Цена</span>
        </div>
      `;
      cartList.innerHTML = titleHTML;
      this.items.forEach((item) => {
        cartList.appendChild(this._createCartElement(item, cartList));
      });
    } else {
      document.querySelector('.cart__form').style.display = 'none';
      const emptyHTML = `
        <div class="cart__empty">
          <span>Корзина пуста</span>
        </div>
      `;
      cartList.style.border = 'none';
      cartList.innerHTML = emptyHTML;
    }
  }

  clear(cartList) {
    this.items = [];
    localStorage.setItem('cart', JSON.stringify(this.items));
    this._setQuantity();
    this.fillCart(cartList);
  }

  getItems() {
    return this.items;
  }
}

export default Cart;
