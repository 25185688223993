const initSidebar = () => {
  const sections = document.querySelectorAll('.sidebar__section');
  sections.forEach((section) => {
    const head = section.firstElementChild;
    head.addEventListener('click', () => {
      const isOpen = section.classList.contains('active');
      sections.forEach((_section) => {
        _section.classList.remove('active');
      });
      if (isOpen) {
        section.classList.remove('active');
      } else {
        section.classList.add('active');
      }
    });
  });

  const subsections = document.querySelectorAll('.sidebar__subsection.parent');
  subsections.forEach((section) => {
    const head = section.firstElementChild;
    head.addEventListener('click', () => {
      const isOpen = section.classList.contains('active');
      subsections.forEach((_section) => {
        _section.classList.remove('active');
      });
      if (isOpen) {
        section.classList.remove('active');
      } else {
        section.classList.add('active');
      }
    });
  });
};

initSidebar();
