class Modal {
  constructor(modalClassName, openButtonClassName) {
    this.modal = document.querySelector(modalClassName);
    this.openButton = document.querySelector(openButtonClassName);
  }

  _initOpenButton() {
    if (!this.openButton) {
      return;
    }

    this.openButton.addEventListener('click', () => {
      this.modal.classList.add('active');
      document.body.style.overflow = 'hidden';
    });
  }

  _initClose() {
    this.modal.addEventListener('click', (event) => {
      if (event.target.classList.contains('active')) {
        this.close();
      }
    });
    const button = this.modal.querySelector('.modal__close');
    button.addEventListener('click', this.close.bind(this));
  }

  addCloseButton(className) {
    const button = this.modal.querySelector(className);
    button.addEventListener('click', this.close.bind(this));
  }

  show() {
    this.modal.classList.add('active');
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.modal.classList.remove('active');
    document.body.style.overflow = 'auto';
  }

  init() {
    this._initOpenButton();
    this._initClose();
  }
}

export default Modal;
