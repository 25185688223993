class Sender {
  constructor(options) {
    this.url = options.url;
    this.labels = options.labelClasses.map((className) =>
      document.querySelector(className)
    );
    this.button = document.querySelector(options.buttonClass);
    this.onComplete = options.onComplete;
    this.additionalFields = options.additionalFields;
  }

  _prepare() {
    this.labels.forEach((label) => {
      const input =
        label.querySelector('input') || label.querySelector('textarea');

      if (label.classList.contains('required')) {
        input.addEventListener('input', () => label.classList.remove('show'));
        input.addEventListener('blur', () => label.classList.remove('show'));
      }
    });
  }

  _clear() {
    this.labels.forEach((label) => {
      const input =
        label.querySelector('input') || label.querySelector('textarea');
      input.value = '';
    });
  }

  _sendInit() {
    this.button.addEventListener('click', (event) => {
      event.preventDefault();
      const formData = new FormData();

      for (let i = 0; i < this.labels.length; i++) {
        const label = this.labels[i];
        const input =
          label.querySelector('input') || label.querySelector('textarea');
        if (label.classList.contains('required') && !input.value) {
          input.focus();
          label.classList.add('show');
          return;
        }

        if (input.type === 'file') {
          formData.append(input.name, input.files[0]);
        } else {
          formData.append(input.name, input.value);
        }
      }

      if (this.additionalFields) {
        Object.keys(this.additionalFields).forEach((key) => {
          formData.append(key, this.additionalFields[key]);
        });
      }

      fetch(this.url, {
        method: 'post',
        body: formData,
      }).then(() => {
        this._clear();
        this.onComplete();
      });
    });
  }

  init() {
    this._prepare();
    this._sendInit();
  }
}

export default Sender;
