import Sender from './helpers/Sender';
import Modal from './helpers/Modal';

export const initCartForm = (cart) => {
  const cartPage = document.querySelector('.cart');

  if (cartPage) {
    const thankModal = new Modal('.modal-thank', null);
    thankModal.addCloseButton('.modal-thank__ok');
    thankModal.init();

    const cartList = document.querySelector('.cart__list');
    const names = cart.getItems().map((item) => `${item.name} - ${item.price}`);

    const cartSender = new Sender({
      url: '/ajax/cart.php',
      labelClasses: [
        '.cart-input__name',
        '.cart-input__phone',
        '.cart-input__email',
      ],
      buttonClass: '.cart__prices',
      onComplete: () => {
        cart.clear(cartList);
        thankModal.show();
        ym(64420804, 'reachGoal', 'kick');
        ga('send', 'event', 'form', 'submit');
      },
      additionalFields: {
        items: JSON.stringify(names),
      },
    });
    cartSender.init();
  }
};
